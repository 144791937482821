.PostsWrap{
    padding:0 1rem 2rem;
    display:flex;
    max-width: 1100px;
    margin:0 auto;
    gap: 1em;

.SinglePost{
    text-align: left;
    width: 75%;
    max-width: 800px;
    margin: 0 auto 2rem;
    font-size: 1rem;
    background: rgba(0,0,0,0.2);
    padding: 1rem 2rem;
    &:nth-of-type(1) {
        //border: 1px solid #fff;
    }
    h4{
        font-size: 1.65rem;
        font-weight: 900;
        text-align: center;
        margin:0.75rem 0;
        &:before{
            display: block;
            content: "";
            width: 50px;
            height: 39px;
            vertical-align: middle;
            background-position: top;
            position: relative;
            background-size: cover;
            background-image: url('/../src/assets/images/icon_header_dashes.png');
            margin: 0 auto;
        }
        a{
            color:#fff;
            text-decoration: none;
        }
    }
    .featured-image{
        padding-bottom: 56.9%;
        background-size: cover;
        margin-bottom: 12px;
        position: relative;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
    }
    hr{
        max-width: 60%;
        border-color: #b77cb0;
        background: #b77cb0;
    }
}
.PostContent {
    margin-bottom: 3rem;
    a{
        color: #76c4f1;
        text-decoration: none;
        &:hover{
            color: #b77cb0;
        }
    }
}
@media(max-width:1023px){
    flex-wrap: wrap;
}
}