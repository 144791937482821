body {
  position: relative;
  margin: 0;
  font-family: 'Lato', sans-serif;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
h1 {
  font-size: 4rem;
  margin-bottom: 0;
  @media(max-width:799px){
    font-size:2rem;
  }
}
#page {
  overflow-x: hidden;
}
/* PARALLAX */

.bg, .bgLayer {
height: 100vh;
}

#parallax {
display: block;
  img:not(.logo){
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom center;
    max-width: 100%;
  }
}
.logo {
  margin: 12% 0 0;
  display: block;
  max-width: 550px;
  @media(max-width:800px){
    margin:5% auto;
    margin-top: calc(25% + 62px);
    margin-bottom: 20%;
    max-width: 90%;
  }
}
#nonparallax {
display: none;
}
#nonparallax {
background-image: url('assets/images/magaari/magaari_background.jpg');
background-position: center;
background-repeat: no-repeat;
background-size: auto 100%;
}
.bg {
position: relative;
z-index: 10;
}

.bgLayer {
background-position: bottom center;
background-size: cover;
background-repeat: no-repeat;
width: 100%;
position: fixed;
  &.parallax {
  position: fixed;
  }
}
#bg-0 {
position: fixed;
width: 100%;
height: 100%;
}
#bg-6 {
  max-width: 1199px;
  margin: 0 auto;
  left: 0;
  right: 0;
  @media (max-width: 1246px) and (min-width: 801px)  {
    margin:5%;
  }
  @media(max-width:800px){
    background: rgba(0,0,0,0.3);
  }
}
@media(min-width:1921px) {
#bg-0 {
  position: fixed;
  width: 100%;
  /*height: auto;*/
  }
  /*#bg-2 {
  max-width: 54%;
  right: 0;
  }*/
}
.Kick{
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0%;
  @media (max-width: 1246px) and (min-width: 800px)  {
    bottom: 10%;
  }
  @media(max-width:800px){
    position: relative;
    right: unset;
    bottom: unset;
    text-align: center;
  }
}
#ks {
  text-align: center;
  max-width: 300px;
  padding: 1em;
  box-shadow: 1px 2px 5px #000;
  background-color: #000;
  transition: transform .25s,opacity .4s;
  transition: transform .25s,opacity .4s,-webkit-transform .25s;
  &:hover {
  cursor: pointer;
  background-color: #05ce78;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  }
  a{
  display:block;
  color:#000;
  text-decoration:none;
  }
  img{
      width: 100%;
      height:auto;
  }
  svg.valign-middle {
  fill: #05ce78;
  }
  h2{
    margin: 0;
    color:#fff;
  }
  @media(max-width:800px){
    margin: 0 auto;
  }
}
#announcements {
position: relative;
z-index: 98;
background: linear-gradient(to bottom, rgba(0,26,47,0) 0%,rgba(0,26,47,1) 30%);
padding-bottom: 2rem;
&:before{
  background: url(./assets/images/magaari/magaari-bg-shadow-style.png), linear-gradient(to bottom,  rgba(0,26,47,0) 0%,rgba(0,26,47,1) 100%); 
  display: block;
  content: "";
  height: 143px;
  background-size: contain;
  width: 100%;
  position: relative;
}
.inner-wrap{
  width: 100%;
  position: relative;
  top: 0;
  text-align: center;
  color: #fff;
  background:  rgba(0, 26, 47, 1) 0%;
  margin-top: -96px;
  padding-top: 57px;
  @media(min-width:1921px){
    padding-top:7%;
  }
  }
}
img.hblue {
    margin: 0 auto 0.85em -2%;
    max-width: 525px;
    height: auto;
  @media(max-width:524px){
    max-width: 100%;
  }
}
#about{
  position: relative;
  z-index: 98;
  background: linear-gradient(to bottom, rgba(0,26,47,1) 0%,rgba(15, 24, 30,1) 100%);
  margin-top: -2px;
  .inner-wrap{
    width:100%;
    position: relative;
    top:0;
    background: url(./assets/images/hex-bg2.png), -moz-linear-gradient(top,  rgba(0,26,47,0) 0%, rgba(15, 24, 30,1) 10%); /* FF3.6-15 */
    background: url(./assets/images/hex-bg2.png), -webkit-linear-gradient(top,  rgba(0,26,47,0) 0%,rgba(15, 24, 30,1) 10%); /* Chrome10-25,Safari5.1-6 */
    background: url(./assets/images/hex-bg2.png), linear-gradient(to bottom,  rgba(0,26,47,0) 0%,rgba(15, 24, 30,1) 10%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    background-position: top;
    background-repeat: repeat-x;
    text-align: center;
    color:#fff;
    padding-top: 67px;
    padding-bottom: 6rem;
    h2{
      background: linear-gradient(to bottom, rgba(0, 26, 47, 0.4) 0%, rgb(15, 24, 30) 100%);
      max-width: 100%;
      margin: 0 auto;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 100% 100%, 0% 100%, 0% 50%);
      display: block;
      font-size: 4rem;
      margin-bottom: 0;
      padding-top:0.8em;
      @media(max-width:799px){
        font-size:2rem;
      }
    }
    .journey {
      padding: 1em;
      min-width: 633px;
      @media(max-width: 991px){
        min-width: auto;
      }
    }
    .generalBG{
      position: relative;
      display:flex;
      align-items: center;
      img{
        width: 33vw;
        max-width: 600px;
        height: auto;
        @media(max-width: 991px){
          width:100%;
          max-width: 100%;
        }
      }
    }      
    @media(max-width:799px){
      padding-bottom:3rem;
    }
  }
}
#faqs{
  position: relative;
  z-index: 98;
  background: linear-gradient(to bottom, rgba(15, 24, 30,1) 0%,rgba(15, 24, 30,1) 100%);
  margin-top: -2px;
  .inner-wrap{
    width:100%;
    position: relative;
    top:0;
    background: url(./assets/images/hex-bg2.png), -moz-linear-gradient(top,  rgba(15, 24, 30,0) 65%, rgba(0, 0, 0,1) 100%); /* FF3.6-15 */
    background: url(./assets/images/hex-bg2.png), -webkit-linear-gradient(top,  rgba(15, 24, 30,0) 65%,rgba(0, 0, 0,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: url(./assets/images/hex-bg2.png), linear-gradient(to bottom,  rgba(15, 24, 30,0) 65%,rgba(0, 0, 0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    background-position: top;
    background-repeat: repeat-x;
    text-align: center;
    color:#fff;
    padding-top: 67px;
    h2{
      background: linear-gradient(to bottom, rgba(0, 26, 47, 0.4) 0%, rgb(15, 24, 30) 100%);
      max-width: 100%;
      margin: 0 auto;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 100% 100%, 0% 100%, 0% 50%);
      display: block;
      font-size: 4rem;
      margin-bottom: 0;
      padding-top:0.8em;
      @media(max-width:799px){
        font-size:2rem;
      }
    }
    }
    .grid {
      border-left: 1px solid;
      @media(max-width: 991px){
          border:none;
      }
    }
    .questions {
      min-height: 50vh;
      padding: 1em;
      min-width: 633px;
      &:before{
        content: '';
        width: 100vw;
        display: block;
        position: absolute;
        height: 1px;
        background: #fff;
        top: 25vh;
        left: calc(-100vw);
      }
      @media(max-width: 991px){
        min-width: auto;
        min-height: auto;
        &:before{
          display:none;
        }
      }
      }
    details{
      background:transparent;
      &[open] > summary {
        background: rgba(118, 196, 241, 0.3);
      }
      summary {
        padding: 1rem;
        cursor: pointer;
        &:hover{
          background: rgba(118,196,241,0.3);
          transition: background 0.5s ease-out;
        }
      }
    }
    .generalBG{
      position: relative;
      img{
        width: 33vw;
        max-width: 1024px;
        height: auto;
        @media(max-width: 991px){
          width:100%;
        }
      }
    }
}
#resources {
  background-color: #000;
  background: url(./assets/images/hex-bg-reverse.png), #000;
  background-repeat: repeat-x;
  background-position: bottom;
  z-index: 100;
  position: relative;
  margin-top: -2px;
  padding-bottom: 12rem;
  @media(min-width:992px){
    padding-top:4em;
  }
  a{
    color:#fff;
    text-decoration: none;
    &:hover{
      color:#b77cb0;
    }
  }
  .inner-wrap{
    width:100%;
    position: relative;
    top:0;
    background: url(./assets/images/hex-bg-black-bottom.png); /* FF3.6-15 */
    background: url(./assets/images/hex-bg-black-bottom.png); /* Chrome10-25,Safari5.1-6 */
    background: url(./assets/images/hex-bg-black-bottom.png); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    background-position: top;
    background-repeat: repeat-x;
    text-align: center;
    color:#fff;
    padding-top: 67px;
    h2{
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgb(0, 0, 0) 100%);
      max-width: 100%;
      margin: 0 auto;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 100% 100%, 0% 100%, 0% 50%);
      display: block;
      font-size: 4rem;
      margin-bottom: 0;
      padding-top:0.8em;
      @media(max-width:799px){
        font-size:2rem;
      }
    }
  }
  .grid {
    border-left: none;
    grid-template-columns: .25fr .25fr;
    justify-content: center;
    @media(max-width:799px){
      grid-template-columns: 1fr;
    }
  }
  .core-set h3, 
  .press-kit h3,
  .card-creator h3 {
  font-size: 1.65rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  }
  .core-set, 
  .press-kit,
  .card-creator  {
    padding-top: 3rem;
    padding-bottom: 3rem;
    @media(max-width:1023px){
      padding-bottom: 2rem;
    }
    @media(max-width:799px){
      padding-top: 0rem;
      padding-bottom: 0rem;
    }
  }      
  .core-set{
    .Home-Button{
      background: url('./assets/images/button_primary_left@2x.png') no-repeat;
      background-size: contain;
      &:after{
        background: url('./assets/images/button_primary_left_glow@2x.png') no-repeat;
      }      
      &:hover{
        background: url('./assets/images/button_primary_left_glow@2x.png') no-repeat;
        background-size: contain;
      }
    }
    @media(max-width:799px){
      padding-top: 3rem;
    }
  }  
  .press-kit{
    .Home-Button{
      background: url('./assets/images/button_primary_right@2x.png') no-repeat;
      background-size: contain;
      &:after{
        background: url('./assets/images/button_primary_right_glow@2x.png') no-repeat;
      }      
      &:hover{
        background: url('./assets/images/button_primary_right_glow@2x.png') no-repeat;
        background-size: contain;
      }
    }
  }
  .card-creator{
    .Home-Button{
      background: url('./assets/images/button_primary_middle_green.png') no-repeat;
      background-size: contain;
      &:after{
        background: url('./assets/images/button_primary_middle_green_glow.png') no-repeat;
      }      
      &:hover{
        background: url('./assets/images/button_primary_middle_green_glow.png') no-repeat;
        background-size: contain;
      }
    }
    @media(max-width:799px){
      grid-column: 1;
    }
  }
}
.Home-FlexContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 581px;
  @media(max-width:767px){
    flex-direction: column;
  }
}
.Home-Button{
  width: 262px;
  height: 108px;
  background: url("./assets/images/button_end_turn_og.png") no-repeat;
  background-size: contain;
  display: block;
  margin:0 auto;
  text-align: center;
  position: relative;
  opacity: 1;
  transition: transform 250ms, opacity 400ms;
  &:after{
      visibility: hidden;
      content: url('./assets/images/button_end_turn_v2.png');
      position: absolute;
      left:-999999999999999999px;
  }
  &:hover {
      transform: translateY(-5px);
      background: url("./assets/images/button_end_turn_v2.png") no-repeat;
      background-size: contain;
  }
  a {
      display: block;
      width: 100%;
      height: 55px;
      text-decoration: none;
      color: #fff;
      text-transform: uppercase;
      font-size: 1.15rem;
      position: absolute;
      top: 4px;
      left: 0px;
      right: 0;
      padding-top: 7%;
      text-shadow: 1px 1px 4px #000;
      &.join{
        top: 23px;
        height: 63px;
      }     
       &.singleLine{
        top: 11px;
      }
      span{
          display: block;
          font-size: 0.75rem;
      }
    }
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  max-width: 1100px;
  margin: 0 auto;
  @media(max-width: 991px){
      grid-template-columns: 1fr;
  }
}
.content {
  max-width: 1100px;
  font-size: 1.25rem;
  margin: 0 auto;
  text-align: left;
  padding: 0 1rem;
  a{
    color:#76c4f1;
    font-weight: bold;
    text-decoration: none;
    &:hover{
      color:#b77cb0;
    }
  }
  ul li {
    margin-bottom: 1rem;
  }
  iframe {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 290px;
    width: 100%;
    right: 0;
    margin: 0 auto;
  }
  &.flex{
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }
  hr{
    max-width: 85%;
    margin:4rem auto;
  }
  div{
    &.intro {
    flex-basis: 50%;
    }
    &.vid {
      position: relative;
      width: 100%;
      margin-top: 1em;
      flex-basis: 47%;
    }
  }
  @media(max-width:767px){
    iframe {
      height:100%;
    }
    &.flex{
      flex-direction: column;
    }
    .vid {
      position: relative;
      padding-bottom: 56.25%;
    }
  }
}