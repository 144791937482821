header.primary{
    background-color: black;
    position: fixed;
    z-index: 999;
    width: 100%;
    .NavBar {
        font-size:1.25em;
        max-height: 62px;
        width: 100%;
        margin:0 auto;
        padding: 0 2rem;
        align-items: center;
        max-width: 1199px;
        justify-content: space-between;
        &.flex{
            display:flex;
            gap: var(--gap, 1rem);
        }
        &.scrolled{
            max-width: 1199px;
        }
        .NavBar-Logo {
            flex-basis: 300px;
            text-align: left;

            img{
                max-width: 62px;
                height: auto;
                width: 100%;
            }
            @media(max-width: 800px){
                text-align: left;
            }
        }
        .NavBar-Items{
            .mobile-toggle{
                display:none;
                position:absolute;
                background-color: black;
                width: 1rem;
                width: 48px;
                aspect-ratio: 1;
                top: 0.3rem;
                right: 1rem;
                border:none;
                cursor: pointer;
                z-index: 99999;
                span.bar {
                    display: block;
                    background: #fff;
                    width: 25px;
                    height: 3px;
                    margin: 0.5em auto;
                    transition: all 100ms ease-in-out;
                    &.x:nth-of-type(2) {
                        transition: all 100ms ease-in-out;
                        transform: rotate(45deg);
                        transform-origin: top left;
                        width: 28px;
                      }
                      
                      &.x:nth-of-type(3) {
                        transition: all 100ms ease-in-out;
                        transform-origin: center;
                        width: 0;
                      }
                      
                      &.x:nth-of-type(4) {
                        transition: all 100ms ease-in-out;
                        transform: rotate(-45deg);
                        transform-origin: bottom left;
                        width: 28px;
                      }
                }
                @media(max-width:800px){

                    display: block;
                }
            }
            nav{
                ul{
                    display:flex;
                    list-style-type: none;
                    padding:0;
                    margin: 0;
                    transition: 350ms ease-out;
                    &[data-visible="true"]{
                        transform:translateX(0);
                        right:0;
                    }
                li{
                    margin-inline: 1rem;
                    a{
                        color:#fff;
                        text-decoration: none;
                    }
                }
                }
                @media(max-width: 800px){
                    ul{
                        position: fixed;
                        inset: 0 0 0 30%;
                        background-color: #000;
                        flex-direction: column;
                        padding: min(30vh, 10rem) 2rem;
                        transform:translateX(100%);
                        @supports(backdrop-filter: blur(0.5rem)){
                            backdrop-filter: blur(0.5rem);
                            background: hsl(0 0% 0% / 0.6);
                        }
                        a{
                            padding: 1em;
                            display: block;
                            font-size: 1.2rem;
                        }
                    }
                }
            }
            @media(max-width:1246px){
                margin-right: calc(10% - 2rem);
            }
        }
    }
}