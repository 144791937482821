.Social{
    text-align: center;
    &.Showcase{
        max-width: 300px;
        margin: 3rem auto 4rem;
        p{
            color: #fff;
            margin-bottom: 0;
            font-size: 1.5rem;
            text-shadow: 1px 2px 2px #333;
        }
        @media(max-width:800px){
            margin:1.5em auto;
        }
    }
    a{
        color:#fff;
        display: inline-block;
        margin: 5% 0.25em 0;
    &#cord:hover {
        color:#7289da;
    }
    &#twitter:hover {
        color:#1d9cf0;
    }
    &#fb:hover{
        color: #4267B2;
    }
    &#insta:hover{
        color: #cd486b;
    }
    &#makeship {
        display: block;
        background-color: rgb(45, 35, 182);
        max-width: 300px;
        font-size: 1.92rem;
        color: #fff;
        padding: 0.3em 0 0.2em;
        text-decoration: none;
        margin-left: 0;
        margin-top: 3rem;
        opacity: 1;
        box-shadow: 1px 2px 5px #000;
        transition: transform 250ms, opacity 400ms;
        span.smTxt{
            display: block;
            font-size: 1.5rem;
        }

        span.tooltip{
            visibility: hidden;
            width: 100%;
            background-color: black;
            color: #fff;
            text-align: center;
            padding: 5px 0;
            position: absolute;
            z-index: 1;
            top: -30px;
            font-size: 1rem;
            left: 0;
            margin: 0 auto;
            right: 0;
            &::after {
                content: " ";
                position: absolute;
                top: 100%; /* At the bottom of the tooltip */
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: black transparent transparent transparent;
              }
          }
        
        svg{
            vertical-align: middle;
            font-size: 2.5rem;
            width: 32px;
            display: inline-block;
            height: auto;
            margin-bottom: 0.15em;
        }
    &:hover{
        background-color: rgb(83, 73, 237);
        transform: translateY(-5px);
        span.tooltip {
            visibility: visible;
          }
    }
    @media(max-width:800px){
        margin:1.5em auto;
        max-width: 239px;
        font-size:1.5em; 
        padding: 0.2em 0;
        span.smTxt{
            margin-bottom: 0.15em;
        }       
        svg{
            font-size: 1.85rem;
            display: inline-block;
        }
      }
    }
    &#youtube:hover{
        color:#FF0000;
    }
    &#link:hover{
       color:#E3EEC0;
    }
    }
    @media(min-width:800px){
        max-width: 300px;
        margin-left: 125px;
    }
}