footer {
    position: relative;
    z-index:999;
    background-color: #000;
    color: #fff;
    padding: 1rem 2rem 0.8rem;
    margin-top: -1px;
    .footContent {
        display: flex;
        justify-content: space-between;
        max-width: 1100px;
        margin: 0 auto;
    }
    .footSocial {
        align-self: center;
        .Social a {
            color: #fff;
            &#steam {
                display: inline-block;
                background-color: transparent;
                max-width: 100%;
                font-size: inherit;
                color: #fff;
                padding: 0;
                text-decoration: none;
                margin: 5% 0.25em 0;
                opacity: 1;
                box-shadow: none;
                transition: transform 250ms, opacity 400ms;
                svg{
                    font-size: 2em;
                }
                &:hover{
                    background-color: transparent;
                    transform: none;
                    color:#2a475e;
                }
            }
        }
    }
}
@media(max-width:649px){
    footer {
        text-align: center;
        padding-bottom:1rem;
        .footContent{
            flex-direction: column;
        }
    }
}